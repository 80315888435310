import { device } from "src/styles/breakpoints"
import { grayscale } from "src/styles/colors"
import * as newColors from 'src/styles/newColors'
import styled from "styled-components"
import * as T from './_types'

export const Section = styled.section`
  background-color: ${grayscale[500]};
  padding: 40px 0 0 0;

  @media ${device.tablet} {
    padding: 40px 0 0 0;
  }

  @media ${device.desktopLG} {
    padding: 80px 0 0 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0 0 0;
  }
`

export const Title = styled.h2`
  font-family: "Citrina", Helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: white;
  margin: 0 auto 16px auto;

  @media ${device.tablet} {
    margin-bottom: 16px;

    font-size: 40px;
    line-height: 44px;
  }

  @media ${device.desktopLG} {
    font-size: 48px;
    line-height: 53px;

    width: 85%;
    margin-bottom: 10px;
  }

  @media ${device.desktopXXL} {
    font-size: 56px;
    line-height: 62px;

    width: 90%;
    margin-bottom: 12px;
  }
`

export const Description = styled.p`
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: white;
  margin-bottom: 10px;

  @media ${device.desktopLG} {
    margin-bottom: 26px;
  }
`

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media ${device.tablet} {
    gap: 24px;
  }

  @media ${device.desktopLG} {
    gap: 40px;
  }
`

export const Card = styled.div`
  background-color: #ffc89c;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  width: 156px;
  height: 359px;

  @media ${device.desktopLG} {
    width: 216px;
    height: 498px;
  }

  @media ${device.desktopXXL} {
    width: 250px;
  }
`

export const CardImage = styled.img`
  background-color: ${newColors.primary[200]};
  width: 100%;
  height: 203px;
  border-radius: 12px;

  position: absolute;
  top: 0;

  @media ${device.tablet} {
    height: 190px;
  }
  @media ${device.desktopLG} {
    height: 284px;
  }
  @media ${device.desktopXXL} {}
`

export const CardTextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
  
  background-color: white;
  width: 100%;
  height: auto;
  min-height: 130px;

  border-radius: 0 0 12px 12px;
  padding: 11.5px;


  @media ${device.tablet} {
    min-height: 144px;
  }
  @media ${device.desktopLG} {
    min-height: 179px;
  }

  .enjoy_button {
    font-family: "Inter", Helvetica, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    padding: 6px 11.5px;

    margin-top: auto;

    &:focus {
      border: none !important;
      padding: 6px 11.5px;

      @media ${device.tablet} {
        padding: 10px 11.5px;
      }
    }

    &:hover {
      background-color: ${newColors.primary[400]};
    }

    @media ${device.tablet} {
      padding: 10px 11.5px;
    }
    @media ${device.desktopLG} {}
    @media ${device.desktopXXL} {}
  }
`

const LabelsColors = {
  green: newColors.secundaryGreen,
  pink: newColors.secundaryPink,
  yellow: newColors.secundaryYellow,
  blue: newColors.secundaryBlue,
}

export const CardLabel = styled.div<T.CardLabelColor>`
  background-color: ${({ color }: T.CardLabelColor) => LabelsColors[color] || LabelsColors.blue};
  width: 100%;
  z-index: 2;

  font-family: "Citrina", Helvetica, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  
  text-align: center;
  padding: 6px 0;
  color: ${grayscale[500]};

  @media ${device.desktopLG} {
    font-size: 16px;
    line-height: 19px;
    padding: 8px 0;
  }
`

export const CardTitle = styled.h3`
  font-family: "Citrina", Helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  text-align: center;
  color: ${grayscale[500]};
  margin: 0 0 6px;
  z-index: 1;

  @media ${device.desktopLG} {
    font-size: 24px;
    line-height: 29px;

    margin-bottom: 8px;
  }
`

export const CardDescription = styled.p`
  font-family: "Citrina", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: ${grayscale[500]};
  text-align: center;

  margin: 0 0 5px 0;

  @media ${device.desktopLG} {
    font-size: 16px;
    line-height: 19px;
  }

  .cartao_black {
    font-family: "Inter", Helvetica, sans-serif;
    font-weight: 600;
    font-size: 7px;
    line-height: 9px;
    text-align: center;
    color: white;

    margin: 8px auto 0 auto;
    background-color: ${grayscale[500]};
    padding: 2px 4px;
    border-radius: 4px;

    width: 62px;

    @media ${device.desktopLG} {
      font-size: 10px;
      line-height: 12px;

      width: 82px;
    }
  }
`
