import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${grayscale[600]};

  padding: 40px 0;
  
  @media ${device.tablet} {
    padding: 40px 0;

  }

  @media ${device.desktopLG} {
    padding: 80px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  a {
    cursor: pointer;
    margin: 0px 4px;

    @media ${device.tablet} {
      margin: 0px 15px;
    }

    &:hover {
      color: ${orange.extra} !important;
    }
  }
`

export const PromotionProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media ${device.tablet} {
    justify-content: center;
  }
`

export const Title = styled.h2`
  font-family: "Citrina", Helvetica, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  text-align: center;
  color: white;

  margin-bottom: 8px;
  
  @media ${device.desktopXXL} {
    font-size: 56px;
    line-height: 62px;
  }
`

export const Description = styled.p`
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: white;

  margin-bottom: 32px;

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 22px;
  }

  @media ${device.desktopLG} {
    font-size: 16px;
    line-height: 19px;
  }
`
